import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#515151',
                secondary: '#681919',
                secondary_yec: '#F86624',
            },
        },
    },
});
