<template>
  <v-container>
    <v-row class="display-3 mt-10 mb-5" justify="center">Albo d'Oro S. Martino</v-row>
    <v-row>
      <v-col cols="12" md="6" lg="4" class="pa-5" v-for="(titolo,index) in albodoro" :key="index">
        <div class="title">{{titolo.titolo}}</div>
        <v-simple-table v-if="titolo.elenco[0].giocatore">
          <template v-slot:default>
            <thead class="grey darken-2">
              <tr>
                <th class="text-left white--text">Anno</th>
                <th class="text-left white--text">Giocatore</th>
                <th class="text-left white--text">Totale</th>
                <th class="text-left white--text">Media</th>
              </tr>
            </thead>
            <tbody>
              <tr :class="index%2==0 ? '' : 'grey lighten-3'" v-for="(item,index) in titolo.elenco" :key="item.anno">
                <td>{{ item.anno }}</td>
                <td>
                  <span v-if="item.giocatore == null || item.giocatore == ''">Non Assegnato</span>
                  <span v-else>{{ item.giocatore }}</span>
                </td>
                <td>{{ item.tot }}</td>
                <td>{{ item.media }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-simple-table v-else>
          <template v-slot:default>
            <thead class="grey darken-2">
              <tr>
                <th class="text-left white--text">Anno</th>
                <th class="text-left white--text">Squadra</th>
                <th class="text-left white--text">Totale</th>
                <th class="text-left white--text">Media</th>
              </tr>
            </thead>
            <tbody>
              <tr :class="index%2==0 ? '' : 'grey lighten-3'" v-for="(item,index) in titolo.elenco" :key="item.anno">
                <td>{{ item.anno }}</td>
                <td>
                  <span v-if="item.squadra == null || item.squadra == ''">Non Assegnato</span>
                  <span v-else>{{ item.squadra }}</span>
                </td>
                <td>{{ item.tot }}</td>
                <td>{{ item.media }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "sanmartino-item",
  data() {
    return {
      albodoro: [
        {
          titolo: "Schuler",
          elenco: [
            {
              anno: 2003,
              giocatore: "Agostino Jessica",
              tot: "107",
              media: "35,7"
            },
            {
              anno: 2004,
              giocatore: "Levis Lorenzo",
              tot: "107",
              media: "35,7"
            },
            {
              anno: 2005,
              giocatore: "Levis Lorenzo",
              tot: "106",
              media: "35,3"
            },
            {
              anno: 2006,
              giocatore: "Levis Lorenzo",
              tot: "95",
              media: "31,7"
            },
            {
              anno: 2007,
              giocatore: "Levis Lorenzo",
              tot: "90",
              media: "30,0"
            },
            {
              anno: 2008,
              giocatore: "Bellucci Nicola",
              tot: "92",
              media: "30,7"
            },
            {
              anno: 2009,
              giocatore: "Prestinari Emanuele",
              tot: "91",
              media: "30,3"
            },
            {
              anno: 2010,
              giocatore: "Prestinari Emanuele",
              tot: "88",
              media: "29,3"
            },
            {
              anno: 2011,
              giocatore: null,
              tot: null,
              media: null
            },
            {
              anno: 2012,
              giocatore: "Diotti Daniele",
              tot: "102",
              media: "34,0"
            },
            {
              anno: 2017,
              giocatore: "Diotti Matteo",
              tot: "69",
              media: "34,5"
            },
            {
              anno: 2018,
              giocatore: "Broggi Marco",
              tot: "95",
              media: "31,7"
            },
            {
              anno: 2019,
              giocatore: "Broggi Marco",
              tot: "64",
              media: "32,0"
            }
          ]
        },
        {
          titolo: "Juniores",
          elenco: [
            {
              anno: 2000,
              giocatore: "Perazzolo Niccolò",
              tot: 113,
              media: "37,7"
            },
            {
              anno: 2001,
              giocatore: "Perazzolo Niccolò",
              tot: 107,
              media: "35,7"
            },
            {
              anno: 2002,
              giocatore: "Tiekstra Erik",
              tot: 88,
              media: "29,3"
            },
            {
              anno: 2003,
              giocatore: "Mariotti Marcello",
              tot: 101,
              media: "33,7"
            },
            {
              anno: 2004,
              giocatore: "Eigmann Marco",
              tot: 100,
              media: "33,3"
            },
            {
              anno: 2005,
              giocatore: "Arzuffi Boris",
              tot: 96,
              media: "32,0"
            },
            {
              anno: 2006,
              giocatore: "Arzuffi Boris",
              tot: 92,
              media: "30,7"
            },
            {
              anno: 2007,
              giocatore: "Arzuffi Boris",
              tot: 95,
              media: "31,7"
            },
            {
              anno: 2008,
              giocatore: "Levis Lorenzo",
              tot: 93,
              media: "31,0"
            },
            {
              anno: 2009,
              giocatore: "Levis Lorenzo",
              tot: 86,
              media: "28,7"
            },
            {
              anno: 2011,
              giocatore: "Prestinari Emanuele",
              tot: 94,
              media: "31,3"
            },
            {
              anno: 2013,
              giocatore: "Cheli Duccio",
              tot: 94,
              media: "31,3"
            },
            {
              anno: 2014,
              giocatore: "Diotti Daniele",
              tot: 98,
              media: "32,7"
            },
            {
              anno: 2015,
              giocatore: "Diotti Daniele",
              tot: 98,
              media: "32,7"
            },
            {
              anno: 2016,
              giocatore: "Nadine Juergens",
              tot: 98,
              media: "32,66666667"
            },
            {
              anno: 2017,
              giocatore: "Nadine Juergens",
              tot: 70,
              media: "35"
            },
            {
              anno: 2018,
              giocatore: "Schmid Ramona",
              tot: 110,
              media: "36,66666667"
            },
            {
              anno: 2019,
              giocatore: "Schmid Ramona",
              tot: 67,
              media: "33,5"
            }
          ]
        },
        {
          titolo: "Donne",
          elenco: [
            {
              anno: 2000,
              giocatore: "Barcellini Laura",
              tot: 101,
              media: "33,7"
            },
            {
              anno: 2001,
              giocatore: "Barcelllini Laura",
              tot: 95,
              media: "31,7"
            },
            {
              anno: 2002,
              giocatore: "Tecchio Paola",
              tot: 102,
              media: "34,0"
            },
            {
              anno: 2003,
              giocatore: "Ridolfi Monica",
              tot: 100,
              media: "33,3"
            },
            {
              anno: 2004,
              giocatore: "Detomi Tiziana",
              tot: 96,
              media: "32,0"
            },
            {
              anno: 2005,
              giocatore: "Catanzaro Anna",
              tot: 107,
              media: "35,7"
            },
            {
              anno: 2006,
              giocatore: "Catanzaro Anna",
              tot: 102,
              media: "34,0"
            },
            {
              anno: 2007,
              giocatore: "Catanzaro Anna",
              tot: 93,
              media: "31,0"
            },
            {
              anno: 2008,
              giocatore: "Bandera Anna",
              tot: 97,
              media: "32,3"
            },
            {
              anno: 2009,
              giocatore: "Catanzaro Anna",
              tot: 92,
              media: "30,7"
            },
            {
              anno: 2010,
              giocatore: "Catanzaro Anna",
              tot: 98,
              media: "32,7"
            },
            {
              anno: 2011,
              giocatore: "Ruggiero Cristina",
              tot: 101,
              media: "33,7"
            },
            {
              anno: 2012,
              giocatore: "Bandera Silvia",
              tot: 100,
              media: "33,3"
            },
            {
              anno: 2013,
              giocatore: "Bandera Anna",
              tot: 88,
              media: "29,3"
            },
            {
              anno: 2014,
              giocatore: "Bandera Anna",
              tot: 90,
              media: "30,0"
            },
            {
              anno: 2015,
              giocatore: "Saletta Martina",
              tot: 100,
              media: "33,3"
            },
            {
              anno: 2016,
              giocatore: "Bandera Anna",
              tot: 93,
              media: "31,0"
            },
            {
              anno: 2017,
              giocatore: "Saletta Martina",
              tot: 63,
              media: "31,5"
            },
            {
              anno: 2018,
              giocatore: "Bandera Anna",
              tot: 100,
              media: "33,3"
            },
            {
              anno: 2019,
              giocatore: "Juhola Mirva",
              tot: 66,
              media: "33,0"
            }
          ]
        },
        {
          titolo: "Uomini",
          elenco: [
            {
              anno: 2000,
              giocatore: "Garbui Roberto",
              tot: 89,
              media: "29,7"
            },
            {
              anno: 2001,
              giocatore: "Mattaini Guido",
              tot: 90,
              media: "30,0"
            },
            {
              anno: 2002,
              giocatore: "Giroldini Rudi",
              tot: 91,
              media: "30,3"
            },
            {
              anno: 2003,
              giocatore: "Ciceri Massimiliano",
              tot: 92,
              media: "30,7"
            },
            {
              anno: 2004,
              giocatore: "Prestinari Sergio",
              tot: 92,
              media: "30,7"
            },
            {
              anno: 2005,
              giocatore: "Ciceri Massimiliano",
              tot: 53,
              media: "17,7"
            },
            {
              anno: 2006,
              giocatore: "Giroldini Rudi",
              tot: 90,
              media: "30,0"
            },
            {
              anno: 2007,
              giocatore: "Porta Paolo",
              tot: 86,
              media: "28,7"
            },
            {
              anno: 2008,
              giocatore: "Giroldini Rudi",
              tot: 83,
              media: "27,7"
            },
            {
              anno: 2009,
              giocatore: "Porta Paolo",
              tot: 84,
              media: "28,0"
            },
            {
              anno: 2010,
              giocatore: "Giroldini Rudi",
              tot: 91,
              media: "30,3"
            },
            {
              anno: 2011,
              giocatore: "Baruscotti Yvan",
              tot: 91,
              media: "30,3"
            },
            {
              anno: 2012,
              giocatore: "Giroldini Rudi",
              tot: 89,
              media: "29,7"
            },
            {
              anno: 2013,
              giocatore: "Garbui Domenico",
              tot: 87,
              media: "29,0"
            },
            {
              anno: 2014,
              giocatore: "Levis Lorenzo",
              tot: 85,
              media: "28,3"
            },
            {
              anno: 2015,
              giocatore: "Giroldini Rudi",
              tot: 84,
              media: "28,0"
            },
            {
              anno: 2016,
              giocatore: "Porta Paolo",
              tot: 84,
              media: "28,0"
            },
            {
              anno: 2017,
              giocatore: "Levis Lorenzo",
              tot: 61,
              media: "30,5"
            },
            {
              anno: 2018,
              giocatore: "Giroldini Rudi",
              tot: 90,
              media: "30,0"
            },
            {
              anno: 2019,
              giocatore: "Garbui Domenico",
              tot: 54,
              media: "27,0"
            }
          ]
        },
        {
          titolo: "Senior Femminile",
          elenco: [
            {
              anno: 2000,
              giocatore: "Cibin Luisa",
              tot: "101",
              media: "33,7"
            },
            {
              anno: 2001,
              giocatore: "Cibin Luisa",
              tot: "110",
              media: "36,7"
            },
            {
              anno: 2002,
              giocatore: "Brem Paulette",
              tot: "109",
              media: "36,3"
            },
            {
              anno: 2003,
              giocatore: "Iommarini Ilona",
              tot: "110",
              media: "36,7"
            },
            {
              anno: 2004,
              giocatore: "Prina Lauretta",
              tot: "108",
              media: "36,0"
            },
            {
              anno: 2005,
              giocatore: "Cibin Luisa",
              tot: "106",
              media: "35,3"
            },
            {
              anno: 2006,
              giocatore: "Cibin Luisa",
              tot: "102",
              media: "34,0"
            },
            {
              anno: 2007,
              giocatore: "Goglione Susy",
              tot: "100",
              media: "33,3"
            },
            {
              anno: 2008,
              giocatore: "Iommarini Ilona",
              tot: "107",
              media: "35,7"
            },
            {
              anno: 2009,
              giocatore: "Prina Emilia",
              tot: "96",
              media: "32,0"
            },
            {
              anno: 2010,
              giocatore: "Prina Emilia",
              tot: "107",
              media: "35,7"
            },
            {
              anno: 2011,
              giocatore: "Margarete Almanstorfer",
              tot: "107",
              media: "35,7"
            },
            {
              anno: 2012,
              giocatore: "Dagmar Hirschmann",
              tot: "102",
              media: "34,0"
            },
            {
              anno: 2013,
              giocatore: "Dagmar Hirschmann",
              tot: "96",
              media: "32,0"
            },
            {
              anno: 2014,
              giocatore: null,
              tot: null,
              media: null
            },
            {
              anno: 2015,
              giocatore: "Catanzaro Anna",
              tot: "102",
              media: "34,0"
            },
            {
              anno: 2016,
              giocatore: "Catanzaro Anna",
              tot: "103",
              media: "34,3"
            },
            {
              anno: 2017,
              giocatore: "Catanzaro Anna",
              tot: "57",
              media: "28,5"
            },
            {
              anno: 2018,
              giocatore: "Alexandra Von Dem Knesebeck",
              tot: "100",
              media: "33,3"
            },
            {
              anno: 2019,
              giocatore: "Hirschmann Dagmar",
              tot: "62",
              media: "31,0"
            }
          ]
        },
        {
          titolo: "Senior Maschile",
          elenco: [
            {
              anno: 2000,
              giocatore: "Bozzetti Rodolfo",
              tot: 95,
              media: "31,7"
            },
            {
              anno: 2001,
              giocatore: "Bozzetti Rodolfo",
              tot: 94,
              media: "31,3"
            },
            {
              anno: 2002,
              giocatore: "Ravizzoli Giancarlo",
              tot: 86,
              media: "28,7"
            },
            {
              anno: 2003,
              giocatore: "Crespi Cesare",
              tot: 94,
              media: "31,3"
            },
            {
              anno: 2004,
              giocatore: "Mattaini Guido",
              tot: 93,
              media: "31,0"
            },
            {
              anno: 2005,
              giocatore: "Di Bella Antonio",
              tot: 96,
              media: "32,0"
            },
            {
              anno: 2006,
              giocatore: "Mattaini Guido",
              tot: 87,
              media: "29,0"
            },
            {
              anno: 2007,
              giocatore: "Crespi Cesare",
              tot: 92,
              media: "30,7"
            },
            {
              anno: 2008,
              giocatore: "Sirianni Pietro",
              tot: 93,
              media: "31,0"
            },
            {
              anno: 2009,
              giocatore: "Benvenuto Luigi",
              tot: 91,
              media: "30,3"
            },
            {
              anno: 2010,
              giocatore: "Di Bella Antonio",
              tot: 92,
              media: "30,7"
            },
            {
              anno: 2011,
              giocatore: "Di Bella Antonio",
              tot: 93,
              media: "31,0"
            },
            {
              anno: 2012,
              giocatore: "Marchiani Pierluigi",
              tot: 84,
              media: "28,0"
            },
            {
              anno: 2013,
              giocatore: "Di Bella Antonio",
              tot: 89,
              media: "29,7"
            },
            {
              anno: 2014,
              giocatore: "Kaufmann Dieter",
              tot: 90,
              media: "30,0"
            },
            {
              anno: 2015,
              giocatore: "Diotti Massimo",
              tot: 91,
              media: "30,3"
            },
            {
              anno: 2016,
              giocatore: "Curd Juergens",
              tot: 88,
              media: "29,3"
            },
            {
              anno: 2017,
              giocatore: "Leuci Francesco",
              tot: 54,
              media: "27,0"
            },
            {
              anno: 2018,
              giocatore: "Mattaini Guido",
              tot: 91,
              media: "30,3"
            },
            {
              anno: 2019,
              giocatore: "Mattaini Guido",
              tot: 58,
              media: "29,0"
            }
          ]
        },
        {
          titolo: "Squadre Femminili",
          elenco: [
            {
              anno: 2002,
              squadra: "A.S.M.C. Follonica",
              tot: 217,
              media: "36,2"
            },
            {
              anno: 2003,
              squadra: "A.S.M.C. Follonica",
              tot: 212,
              media: "35,3"
            },
            {
              anno: 2004,
              squadra: "M.M.C. Paderno ",
              tot: 214,
              media: "35,7"
            },
            {
              anno: 2005,
              squadra: "M.M.C. Paderno",
              tot: 218,
              media: "36,3"
            }
          ]
        },
        {
          titolo: "Squadre",
          elenco: [
            {
              anno: 2000,
              squadra: "G.S.P. Vergiate 1",
              tot: 371,
              media: "30,91666667"
            },
            {
              anno: 2001,
              squadra: "G.S.P. Vergiate 1",
              tot: 381,
              media: "31,75"
            },
            {
              anno: 2002,
              squadra: "G.S.P. Vergiate 2",
              tot: 252,
              media: "31,5"
            },
            {
              anno: 2003,
              squadra: "G.S.P. Vergiate 2",
              tot: 249,
              media: "31,125"
            },
            {
              anno: 2004,
              squadra: "G.S.P. Vergiate 2",
              tot: 249,
              media: "31,125"
            },
            {
              anno: 2005,
              squadra: "G.S.P. Vergiate 2",
              tot: 257,
              media: "32,125"
            },
            {
              anno: 2006,
              squadra: "G.S.P. Vergiate 1",
              tot: 247,
              media: "30,875"
            },
            {
              anno: 2007,
              squadra: "M.M.C. Paderno 1",
              tot: 245,
              media: "30,625"
            },
            {
              anno: 2008,
              squadra: "G.S.P. Vergiate 1",
              tot: 242,
              media: "30,25"
            },
            {
              anno: 2009,
              squadra: "G.S.P. Vergiate 1 ",
              tot: 236,
              media: "29,5"
            },
            {
              anno: 2010,
              squadra: "G.S.P. Vergiate 1 ",
              tot: 241,
              media: "30,125"
            },
            {
              anno: 2011,
              squadra: "G.S.P. Vergiate 2",
              tot: 250,
              media: "31,25"
            },
            {
              anno: 2012,
              squadra: "G.S.P. Vergiate 1 ",
              tot: 239,
              media: "29,875"
            },
            {
              anno: 2013,
              squadra: "G.S.P. Vergiate 1 ",
              tot: 236,
              media: "29,5"
            },
            {
              anno: 2014,
              squadra: "G.S.P. Vergiate 1 ",
              tot: 244,
              media: "30,5"
            },
            {
              anno: 2015,
              squadra: "G.S.P. Vergiate 1 ",
              tot: 236,
              media: "29,5"
            },
            {
              anno: 2016,
              squadra: "G.S.P. Vergiate 1 ",
              tot: 244,
              media: "30,5"
            },
            {
              anno: 2017,
              squadra: null,
              tot: null,
              media: ""
            },
            {
              anno: 2018,
              squadra: "G.S.P Vergiate 1",
              tot: 256,
              media: "32"
            },
            {
              anno: 2019,
              squadra: "G.S.P Vergiate 1",
              tot: 237,
              media: "29,625"
            }
          ]
        },
        {
          titolo: "Assoluto Femminile",
          elenco: [
            {
              anno: 2000,
              giocatore: "Barcellini Laura",
              tot: "101",
              media: "33,7"
            },
            {
              anno: 2001,
              giocatore: "Barcellini Laura",
              tot: "95",
              media: "31,7"
            },
            {
              anno: 2002,
              giocatore: "Tecchio Paola",
              tot: "102",
              media: "34,0"
            },
            {
              anno: 2003,
              giocatore: "Ridolfi Monica",
              tot: "100",
              media: "33,3"
            },
            {
              anno: 2004,
              giocatore: "Detomi Tiziana",
              tot: "96",
              media: "32,0"
            },
            {
              anno: 2005,
              giocatore: "Cibin Luisa",
              tot: "106",
              media: "35,3"
            },
            {
              anno: 2006,
              giocatore: "Catanzaro Anna",
              tot: "102",
              media: "34,0"
            },
            {
              anno: 2007,
              giocatore: "Catanzaro Anna",
              tot: "93",
              media: "31,0"
            },
            {
              anno: 2008,
              giocatore: "Bandera Anna",
              tot: "97",
              media: "32,3"
            },
            {
              anno: 2009,
              giocatore: "Catanzaro Anna",
              tot: "92",
              media: "30,7"
            },
            {
              anno: 2010,
              giocatore: "Catanzaro Anna",
              tot: "98",
              media: "32,7"
            },
            {
              anno: 2011,
              giocatore: "Margarete Almanstorfer",
              tot: "107",
              media: "35,7"
            },
            {
              anno: 2012,
              giocatore: "Bandera Silvia",
              tot: "100",
              media: "33,3"
            },
            {
              anno: 2013,
              giocatore: "Bandera Anna",
              tot: "88",
              media: "29,3"
            },
            {
              anno: 2014,
              giocatore: "Bandera Anna",
              tot: "90",
              media: "30,0"
            },
            {
              anno: 2015,
              giocatore: "Saletta Martina",
              tot: "100",
              media: "33,3"
            },
            {
              anno: 2016,
              giocatore: "Bandera Anna",
              tot: "93",
              media: "31,0"
            },
            {
              anno: 2017,
              giocatore: null,
              tot: "",
              media: ""
            },
            {
              anno: 2018,
              giocatore: "Bandera Anna",
              tot: "100",
              media: "33,3"
            },
            {
              anno: 2019,
              giocatore: "Juhola Mirva",
              tot: "66",
              media: "33,0"
            }
          ]
        },
        {
          titolo: "Assoluto Maschile",
          elenco: [
            {
              anno: 2000,
              giocatore: "Garbui Roberto",
              tot: 89,
              media: "29,7"
            },
            {
              anno: 2001,
              giocatore: "Mattaini Guido",
              tot: 90,
              media: "30,0"
            },
            {
              anno: 2002,
              giocatore: "Ravizzoli Giancarlo",
              tot: 86,
              media: "28,7"
            },
            {
              anno: 2003,
              giocatore: "Ciceri Massimiliano",
              tot: 92,
              media: "30,7"
            },
            {
              anno: 2004,
              giocatore: "Prestinari Sergio",
              tot: 92,
              media: "30,7"
            },
            {
              anno: 2005,
              giocatore: "Ciceri Massimiliano",
              tot: 53,
              media: "17,7"
            },
            {
              anno: 2006,
              giocatore: "Mattaini Guido",
              tot: 87,
              media: "29,0"
            },
            {
              anno: 2007,
              giocatore: "Porta Paolo",
              tot: 86,
              media: "28,7"
            },
            {
              anno: 2008,
              giocatore: "Giroldini Rudi",
              tot: 83,
              media: "27,7"
            },
            {
              anno: 2009,
              giocatore: "Porta Paolo",
              tot: 84,
              media: "28,0"
            },
            {
              anno: 2010,
              giocatore: "Giroldini Rudi",
              tot: 91,
              media: "30,3"
            },
            {
              anno: 2011,
              giocatore: "Baruscotti Yvan",
              tot: 91,
              media: "30,3"
            },
            {
              anno: 2012,
              giocatore: "Marchiani Pierluigi",
              tot: 84,
              media: "28,0"
            },
            {
              anno: 2013,
              giocatore: "Garbui Domenico",
              tot: 87,
              media: "29,0"
            },
            {
              anno: 2014,
              giocatore: "Levis Lorenzo",
              tot: 85,
              media: "28,3"
            },
            {
              anno: 2015,
              giocatore: "Giroldini Rudi",
              tot: 84,
              media: "28,0"
            },
            {
              anno: 2016,
              giocatore: "Porta Paolo",
              tot: 84,
              media: "28,0"
            },
            {
              anno: 2017,
              giocatore: null,
              tot: null,
              media: ""
            },
            {
              anno: 2018,
              giocatore: "Giroldini Rudi",
              tot: 90,
              media: "30,0"
            },
            {
              anno: 2019,
              giocatore: "Garbui Domenico",
              tot: 54,
              media: "27,0"
            }
          ]
        }
      ]
    };
  }
};
</script>