<template>
  <v-container fluid>
    <v-row class="display-3 mt-10" justify="center">Palmares</v-row>
    <v-row class="px-5">
      <v-col cols="12" md="8" offset-md="2">
        <v-row>
          <v-col cols="3" sm="2" md="1"></v-col>
          <v-col cols="4" sm="3" md="3">Competizione</v-col>
          <v-col cols="5" sm="7" md="8">Anni</v-col>
        </v-row>
        <v-divider class="secondary my-1"></v-divider>
        <v-row v-for="(item, index) in palmares" :key="index">
          <v-col
            cols="3"
            sm="2"
            md="1"
            class="subtitle-2"
            align-self="center"
            justify-self="center"
          >
            <v-img
              height="50"
              width="50"
              :src="require('../assets/img/trophy/' + item.logo)"
            ></v-img>
          </v-col>
          <v-col cols="4" sm="3" md="3" class="subtitle-2"
            >{{ item.nome }} ({{ item.anni.length }})</v-col
          >
          <v-col cols="5" sm="7" md="8" class="subtitle-2">
            <span v-for="(anno, index) in item.anni" :key="index"
              >{{ anno }} -</span
            >
          </v-col>
          <v-divider></v-divider>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "palmares-item",
  components: {},
  data() {
    return {
      palmares: [
        {
          logo: "scudetto.png",
          nome: "Campionati Italiani Squadre Uomini",
          anni: [
            1991,
            1996,
            1998,
            1999,
            2002,
            2003,
            2007,
            2008,
            2009,
            2012,
            2014,
            2015,
            2016,
            2017,
            2018,
            2021
          ]
        },
        {
          logo: "scudetto.png",
          nome: "Campionati Italiani Squadre Donne",
          anni: [2005]
        },
        {
          logo: "scudetto.png",
          nome: "Campionati Italiani Squadre Juniores",
          anni: [1992, 1993, 2003, 2012, 2019, 2021]
        },
        {
          logo: "scudetto.png",
          nome: "Campionati Italiani Squadre Seniores",
          anni: [1997, 2003, 2004, 2015, 2017, 2018, 2019, 2021]
        },
        {
          logo: "scudetto.png",
          nome: "Campionati Italiani Sq. 2^ Categoria",
          anni: [2000, 2007]
        },
        {
          logo: "figsp.png",
          nome: "FIGSP League Uomini",
          anni: [
            1998,
            1999,
            2000,
            2002,
            2006,
            2007,
            2008,
            2009,
            2010,
            2011,
            2012,
            2013,
            2014,
            2015,
            2016,
            2017,
            2018,
            2019,
            2021
          ]
        },
        {
          logo: "figsp.png",
          nome: "FIGSP League Donne",
          anni: [2005]
        },
        {
          logo: "coppaitalia.png",
          nome: " Coppa Italia Uomini",
          anni: [1994, 1999, 2002, 2003, 2004, 2008]
        },
        {
          logo: "coppaitalia.png",
          nome: " Coppa Italia Juniores",
          anni: [1990, 1991]
        },
        {
          logo: "club.png",
          nome: "Trofeo Club dell' Anno",
          anni: [2009, 2010]
        }
      ]
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
