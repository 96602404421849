import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Organigramma from './views/Organigramma.vue'
import Palmares from './views/Palmares.vue'
import Galleria from './views/Galleria.vue'
import History from './views/History.vue'
import SanMartino from "./views/SanMartino.vue"
import Documenti from "./views/Documenti.vue"
import Sociali from "./views/Sociali.vue"

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:[
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/organigramma',
      name: 'organigramma',
      component: Organigramma
    },
    {
      path: '/palmares',
      name: 'palmares',
      component: Palmares
    },
    {
      path: '/galleria',
      name: 'galleria',
      component: Galleria
    },
    {
      path: '/storia',
      name: 'history',
      component: History
    },
    {
      path: '/sanmartino',
      name: 'sanmartino',
      component: SanMartino
    },
    {
      path: '/documenti',
      name: 'documenti',
      component: Documenti
    },
    {
      path: '/sociali',
      name: 'sociali',
      component: Sociali
    }
  ]
})