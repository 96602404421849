<template>
  <v-content class="mt-0 pt-0">
    <div class="backgroundMain">
      <v-row>
        <v-col cols="12" md="5" offset-md="1" class="pa-5">
          <v-img
            src="../assets/img/logoGspVergiate.png"
            max-height="700"
            max-width="700"
          ></v-img>
        </v-col>
        <v-col cols="12" md="6" align-self="center" justify-self="center">
          <div
            class="text-center white--text display-4 font-italic font-weight-bold pa-5"
          >
            GSP Vergiate
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="my-10">
      <v-row align="center" class="my-5">
        <v-col cols="12">
          <div class="text-center">
            <h2 class="display-2">GSP Vergiate</h2>
            <span class="display-1"
              >ASD - Assiociazione Sportiva Dilettantistica</span
            >
          </div>
        </v-col>
      </v-row>
      <v-row align="center" class="text-center">
        <v-col cols="12" md="4">
          <v-card flat class="transparent">
            <v-card-text class="text-center">
              <v-icon x-large color="blue-grey darken-2" class="text--lighten-2"
                >mdi-trophy-award</v-icon
              >
            </v-card-text>
            <v-card-title primary-title class="layout justify-center">
              <div class="headline text-center">Trofei FIGSP</div>
            </v-card-title>
            <v-card-text class="display-1">20</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card flat class="transparent">
            <v-card-text class="text-center">
              <v-icon x-large color="blue-grey darken-2" class="text--lighten-2"
                >mdi-shield</v-icon
              >
            </v-card-text>
            <v-card-title primary-title class="layout justify-center">
              <div class="headline">Campionati Italiani</div>
            </v-card-title>
            <v-card-text class="display-1">33</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card flat class="transparent">
            <v-card-text class="text-center">
              <v-icon x-large color="blue-grey darken-2" class="text--lighten-2"
                >mdi-trophy</v-icon
              >
            </v-card-text>
            <v-card-title primary-title class="layout justify-center">
              <div class="headline text-center">Coppe Italia</div>
            </v-card-title>
            <v-card-text class="display-1">8</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-container class="py-2">
      <v-window v-model="carouselModel" vertical show-arrows continuous>
        <v-window-item
          v-for="(image, index) in getCarouselImage"
          :key="index"
          eager
        >
          <v-row justify="center">
            <v-img
              :src="image.src"
              eager
              max-height="700"
              style="whidth:100%"
            ></v-img>
          </v-row>
        </v-window-item>
      </v-window>
    </v-container>
    <div>
      <v-container>
        <v-row justify="center" class="my-12">
          <v-col cols="12" lg="6">
            <v-card flat class="transparent">
              <v-card-title primary-title class="justify-center">
                <div class="headline">Chi siamo...</div>
              </v-card-title>
              <v-card-text>
                Nel giugno 1983 l’inaugurazione dell’impianto e l’apertura al
                pubblico con un afflusso veramente insperato che ha fugato
                immediatamente lo scetticismo iniziale. Quasi tutte le sere il
                minigolf si era trasformato in un punto di ritrovo, aggregazione
                e svago per molti vergiatesi e non. In rare occasioni Vergiate
                era così affollato. Con il passare del tempo, nelle sere estive,
                si sono avvicinati all’impianto vergiatese alcuni praticanti
                abituali del golf su pista che con le loro attrezzature composte
                da diverse palline colorate e con i nomi più strani hanno
                attirato l’attenzione di un gruppo di amici, la maggior parte
                vergiatesi, che iniziavano ad appassionati alla pratica di un
                gioco che da li a poco sarebbe diventato uno sport. Nel 1984
                nasce così il club Golf Su Pista Vergiate.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card flat class="transparent">
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">Contattaci</div>
              </v-card-title>
              <v-card-text class="layout justify-center">
                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-action>
                      <v-btn icon large>
                        <v-icon large class="secondary--text">mdi-phone</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <a href="tel:+39-0331-946754">0331-946754</a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-btn
                        icon
                        large
                        target="_blank"
                        href="https://www.google.com/maps/place/Via+Cusciano,+2,+21029+Vergiate+VA/@45.7240302,8.6928973,17z/data=!3m1!4b1!4m5!3m4!1s0x478664b298cdad01:0xc764666c058c5e5f!8m2!3d45.7240265!4d8.695086?hl=it"
                      >
                        <v-icon large class="secondary--text"
                          >mdi-map-marker</v-icon
                        >
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        >via Cusciano 2, Vergiate (VA)</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-btn
                        icon
                        large
                        target="_blank"
                        href="https://www.facebook.com/groups/gspvergiate/"
                      >
                        <v-icon large class="secondary--text"
                          >mdi-facebook</v-icon
                        >
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Gruppo Facebook</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-footer color="secondary">
      <v-row align="center" class="text-center" no-gutters>
        <v-col cols="12" class="title white--text">Sponsor and Partners</v-col>
        <v-col cols="12" class="white--text"
          >Credono in noi e nel valore dello Sport</v-col
        >
      </v-row>
      <v-row align="center">
        <v-col cols="3" offset="2">
          <div v-for="(item, index) in sponsorCol1" :key="index">
            <v-row>
              <v-col cols="12" md="4" offset-md="4">
                <v-img :src="item.src" width="70"></v-img>
              </v-col>
              <v-col cols="12" md="4" align-self="center" class="white--text">{{
                item.name
              }}</v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="3">
          <div v-for="(item, index) in sponsorCol2" :key="index">
            <v-row>
              <v-col cols="12" md="4" offset-md="4">
                <v-img :src="item.src" width="90"></v-img>
              </v-col>
              <v-col cols="12" md="4" align-self="center" class="white--text">{{
                item.name
              }}</v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="3">
          <div v-for="(item, index) in sponsorCol3" :key="index">
            <v-row>
              <v-col cols="12" md="4" offset-md="4">
                <v-img :src="item.src" width="90"></v-img>
              </v-col>
              <v-col cols="12" md="4" align-self="center" class="white--text">{{
                item.name
              }}</v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-content>
</template>

<script>
export default {
  name: "home-item",
  components: {},
  data() {
    return {
      sponsorCol1: [
        {
          src: require("@/assets/img/sponsor/EMF.png"),
          name: "EMF"
        },
        {
          src: require("@/assets/img/sponsor/WMF.jpg"),
          name: "WMF"
        }
      ],
      sponsorCol2: [
        {
          src: require("@/assets/img/sponsor/eletricpiu_logo.jpg"),
          name: "Eletric Più"
        },
        {
          src: require("@/assets/img/sponsor/lamellare_logo.png"),
          name: "Lamellare Service"
        }
      ],
      sponsorCol3: [
        {
          src: require("@/assets/img/sponsor/comune_logo.jpg"),
          name: "Comue Vergiate"
        },
        {
          src: require("@/assets/img/sponsor/consulta_logo.png"),
          name: "Consulta dello Sport"
        }
      ],
      carouselModel: 0
    };
  },
  created() {
    setInterval(() => {
      if (++this.carouselModel >= 4) this.carouselModel = 0;
    }, 5000);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    getCarouselImage() {
      return [
        {
          src: require("@/assets/img/carousel/minigolf1.jpg")
        },
        {
          src: require("@/assets/img/carousel/minigolf2.jpg")
        },
        {
          src: require("@/assets/img/carousel/minigolf3.png")
        },
        {
          src: require("@/assets/img/carousel/ec2011premiazione.jpg")
        }
      ];
    }
  }
};
</script>

<style>
.backgroundMain {
  background-color: #546e7a;
}
</style>
