<template>
<v-container fluid>
  <div class="display-3 text-center my-10">Organigramma</div>
  <v-container class="mt-10">
    <v-row v-for="(role,index) in organigramma" :key="index" justify="center">
      <v-col cols="5" offset="1" md="3" offset-md="3" o class="text-right headline secondary--text">{{role.role}}</v-col>
      <v-col cols="6" class="headline">{{role.name}}</v-col>
    </v-row>
  </v-container>
  </v-container>
</template>

<script>
export default {
  name: "structure-item",
  data() {
    return {
      organigramma:[
        {
          role: "Presidente",
          name: "Luca Santabarbara"
        },
        {
          role: "Vice Presidente",
          name: "Rudi Giroldini"
        },
        {
          role: "Segretario",
          name: "Guido Mattaini"
        },
        {
          role: "Tesoriere",
          name: "Mauro Levis"
        },
        {
          role: "Commissario Tecnico",
          name: "Lorenzo Levis"
        },
        {
          role: "Consigliere",
          name: "Maurizio Parolin"
        }
      ]
    };
  }
};
</script>
