<template>
  <v-container fluid>
    <div class="display-3 text-center my-10">Documenti Utili</div>
    <v-container class="mt-10">
      <v-row
        v-for="document in documents"
        :key="document.fileName"
        justify="center"
      >
        <v-col
          cols="6"
          md="4"
          offset-md="2"
          o
          class="text-right headline secondary--text"
          >{{ document.label }}</v-col
        >
        <v-col cols="6" class="headline">
          <a
            :href="
              'http://golfsupistavergiate.altervista.org/doc/' +
                document.fileName
            "
            target="_blank"
            class="noDecoration"
            ><v-icon small color="red">mdi-file</v-icon>Download</a
          >
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "documents-item",
  data() {
    return {
      documents: [
        {
          fileName: "atto_costitutivo_gspvergiate.pdf",
          label: "Atto Costitutivo GSP Vergiate"
        },
        {
          fileName: "statuto_gspvergiate.pdf",
          label: "Statuto GSP Vergiate"
        },
        {
          fileName: "regolamento_gspvergiate_2020.pdf",
          label: "Regolamento GSP Vergiate 2020"
        },
        {
          fileName: "Presentazione_FIMg.pdf",
          label: "Presentazione FIMg"
        },
        {
          fileName: "Statuto_FIMg.pdf",
          label: "Statuto FIMg"
        },
        {
          fileName: "Codice_Comportamento_Sportivo_CONI.pdf",
          label: "Codice Comportamento Sportivo CONI"
        },
        {
          fileName: "FIMg_Quote_federali_2021",
          label: "FIMg Quote Federali 2021"
        },
        {
          fileName: "Norme_Antidoping_NADO.pdf",
          label: "Norme Antidoping NADO"
        }
      ]
    };
  }
};
</script>
<style>
.noDecoration {
  text-decoration: none;
}
</style>
