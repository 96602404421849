<template>
  <v-container class="px-12">
    <div class="display-3 text-center my-10">Storia del Club</div>
    <v-row v-for="(p, index) in paragraph" :key="index">
      <v-col cols="12" offset-md="1" offset-lg="2" :md="p.img ? 5: 10" :lg="p.img ? 4: 8">
        <v-img v-if="p.img" :src="p.img"></v-img>
        <p style="text-align: justify;" v-else>{{p.text}}</p>
        <v-img v-if="p.imgFull" :src="p.imgFull"></v-img>
      </v-col>
      <v-col align-self="center" v-if="p.img" cols="12" md="5" lg="4">
        <p style="text-align: justify;">{{p.text}}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "history-item",
  data() {
    return {
      paragraph: [
        {
          text: `La storia del Golf su Pista Vergiate inizia una sera dell’autunno 1982 quando, l’allora Presidente
            della Pro Loco di Vergiate, Silvano Galli, propone al Consiglio Direttivo della pro Loco
            stessa, la costruzione di un impianto di minigolf proprio nel centro del nostro paese. La maggior
            parte dei consiglieri era molto scettica ma l’entusiasmo di Silvano nel descrivere l’impianto, le
            prospettive di utilizzo da parte del pubblico e la possibilità di organizzare gare ha fatto in modo che
            si partisse nella realizzazione del campo di minigolf. Le difficoltà furono molte ma alla fine la
            tenacia e la volontà dell’allora presidente della Pro Loco di realizzare qualcosa di
            nuovo per Vergiate hanno avuto la meglio ed i lavori incominciarono da li a poco.
            Nel giugno 1983 ci fu l’inaugurazione dell’impianto e la conseguente apertura al pubblico con un afflusso veramente
            insperato che ha fugato immediatamente lo scetticismo iniziale. Quasi tutte le sere il minigolf si
            era trasformato in un punto di ritrovo, di aggregazione e svago per molti vergiatesi e non. In rare
            occasioni Vergiate era così affollato. Con il passare del tempo, nelle sere estive, si sono avvicinati
            all’impianto vergiatese alcuni praticanti abituali del golf su pista che con le loro attrezzature
            e numerose palline colorate con i nomi più strani hanno attirato l’attenzione di un gruppo
            di amici, la maggior parte vergiatesi, che iniziavano ad appassionarsi alla pratica di questo nuovo gioco che da
            lì a poco sarebbe diventato uno sport. La fine dell’estate 1983 ha pacato l’entusiasmo iniziale ma
            alla riapertura nella primavera 1984 coloro i quali si erano tanto entusiasmati l’anno prima si sono
            ripresentati ed hanno iniziato, oltre che a praticare sul campo, a richiedere informazioni e consigli
            su come fare per fondare una società sportiva di golf su pista, dove reperire le attrezzature e così
						via.`
        },
        {
          text: `A fine agosto nasceva il Golf su Pista Vergiate su iniziativa di 25 promotori che dopo aver approvato lo Statuto hanno eletto il primo Consiglio Direttivo così composto:
					Presidente: Guido Mattaini
					Vicepresidente: Sandro Bisella
					Segretario e Tesoriere: Mauro Levis
					Consiglieri: Oscar Carboni, Nicoletta Amoresano, Diego Ceretti, Mario Pasinato, Rizzi Tiziano e Mario Saladino.
					Il consiglio neoeletto ha immediatamente avviato le pratiche di affiliazione alla Federazione Italiana Golf su Pista e, di pari passo, è stata iscritta la nostra squadra alla Finale di Coppa Italia
					1984 che si sarebbe disputata sul famosissimo campo di Bovisio Masciago. Sicuramente la manifestazione
					non era alla nostra portata ma l’entusiasmo da una parte e l’opera di convincimento dei
					rappresentanti del Comitato Regionale ci hanno spinto a presentarci. Come da
					pronostico la nostra squadra ha conseguito l’ultimo posto in classifica ad una distanza abissale dai primi. Dopo
					pochi giorni, come benvenuto, il Comitato Regionale Lombardo ha deciso di disputare la finale del
					Campionato territoriale proprio sul campo di Vergiate ed in quell’occasione, seppur con risultati
					sportivi scadenti, abbiamo dimostrato di saper organizzare una manifestazione sportiva già come
					un club collaudato e con un bagaglio di esperienza importante. Oltre ad una premiazione degna
					della manifestazione, abbiamo organizzato per la fine della gara un rinfresco con una torta enorme
					a forma di pista n.1 di minigolf, appositamente confezionata dall’amico, socio e consigliere, Maio
					ladino allora “mastro pasticcere” in quel di Varano Borghi la quale è stata gradita ed apprezzata
					da tutti i partecipanti.`
        },
        {
          img: require("@/assets/img/history/firstTeam.png"),
          text: `Ed ecco la prima divisa 
          ufficiale del club realizzata 
          sulla falsa riga di quella 
          adottata dalla Vergiatese 
          Calcio, con il rosso al posto 
          del granata. Si possono 
          notare i cartelloni pubblicitari 
          smontabili che alcuni volontari 
          hanno realizzato per lo più di 
          notte: la passione per questo 
          nuovo sport era talmente forte 
          che si riuscivano a fare con 
          disinvoltura le più disparate attività e tutto per il bene del minigolf. `
        },
        {
          text: `Nel 1985 appunto, sono arrivate le prime vittorie individuali nelle categorie più basse con Rizzi 
          Tiziano, Massimiliano Ciceri e Guido Mattaini frequenti protagonisti della categoria C maschile. A 
          fine stagione, per i tre vergiatesi appena citati, ci fu la promozione dalla cat. C alla cat. A 
          nazionale saltando a piedi pari la B. Questo fatto ha dato il via ad un incremento dei soci 
          giocatori ma soprattutto del loro livello tecnico che ci ha portato anno dopo anno a partecipare alla 
          maggior parte delle competizioni individuali e a squadre sul territorio nazionale.  
          A fine anni 80 si avvicinarono al minigolf di Vergiate dei ragazzini che poi sarebbero diventati i 
          protagonisti dei maggiori successi del nostro sodalizio. Sotto la guida di Franco Secondelli e 
          Giusppe Viero iniziano a muovere i primi passi Rudi Giroldini, Fulvio Rossi, Ermes Franchini, 
          Domenico e Roberto Garbui provenienti da Lozza, Riccardo Simonetta ed altre giovani leve che 
          giorno dopo giorno, anno dopo anno hanno maturato una grande esperienza che ha portato il Gsp 
          Vergiate a conquistare 4 Coppe Italia Juniores e nel 1990, ad avere il nostro primo Campione 
          Italiano Juniores, Fulvio Rossi di misura su Rudi Giroldini`
        },
        {
          text: `Non sembrava vero, un club nato quasi per scommessa incominciava ad insinuarsi ai vertici delle 
          classifiche a ridosso di club ben più blasonati e da tanti anni (fin dal 1959) presenti sullo scenario 
          del minigolf italiano. Squadre come Bovisio, I Gufi Milano, Monza, Cam Verbania, 
          Castellanza erano sicuramente gli esempi da imitare per raggiungere traguardi sempre più 
          prestigiosi. Forse questa è stata la chiave della nostra crescita: non essere invidiosi delle vittorie 
          altrui ma cercare di carpire i segreti del loro successo e adattarli alla nostra realtà. 
          Un'altra svolta fondamentale è stata la conquista del primo titolo Italiano a squadre proprio sul 
          campo di Bovisio Masciago che aveva visto il nostro esordio 7 anni prima. 
          I protagonisti da allora, Rudi Giroldini, Massimo Pillon, Rodolfo Bozzetti, Tino Fiorani, Roberto 
          Garbui…..hanno raggiunto sul campo considerato "l’Università del Minigolf Italiano", per la sua 
          difficoltà, un risultato a dir poco insperato alla vigilia della manifestazione. 
          A fine 1992 il primo avvicendamento. Il presidente in carica dal 1984 cede la mano e l’assemblea 
          ordinaria 1992 elegge il nuovo presidente ed il consiglio direttivo. 
          Le elezioni danno il seguente risultato: 
          Presidente: Innocente Fiorani 
          Vicepresidente: Ireneo Piazza 
          Consiglieri: Massimiliano Ciceri, Maria Carla Bielli, Giancarlo Ravizzoli, Sergio Prestinari, e Ermes 
          Franchini. `
        },
        {
          text: `La continuità data dal nuovo consiglio ha permesso al club di crescere sempre di più sia dal punto di 
          vista numerico sia dal punto di vista tecnico con sempre più tesserati del Gsp Vergiate chiamati a far parte 
          delle varie rappresentative nazionali.`
        },
        {
          text: `Nel 1995 arriva il secondo titolo italiano a squadre conquistato sul nuovissimo impianto di 
          Canegrate: i nostri rappresentanti furono Rodolfo Bozzetti, Massimiliano Ciceri, Roberto Garbui, 
          Domenico Garbui, Rudi Giroldini, Innocente Fiorani e Massimo Pillon con gli accompagnatori 
          Laura Barcellini e Ermes Franchini`
        },
        {
          text: `Questa nuova figura degli accompagnatori, è stata un’altra chiave di svolta per la nostra 
          crescita per la prima volta ci si sentiva veramente una squadra e se la vittoria di Bovisio 5 anni 
          prima resterà nei nostri cuori poiché è stato il primo successo importante, la vittoria di Canegrate è 
          quella che ha trasformato un gruppo di amici appassionati per lo stesso sport in una squadra vera 
          e propria con appunto gli accompagnatori, disposti a stare 8-10 ore sul campo 
          ininterrottamente per supportare, confortare e aiutare i titolari per il raggiungimento di un obbiettivo 
          comune. La vittoria del Titolo Italiano di Bovisio è stata sicuramente una tappa importante ma a 
          nulla sarebbe valsa se nel frattempo non avessimo avuto la fortuna e, perché no, la bravura di 
          trovare un numero importante di giovanissimi che allora costruirono un gruppo che a livello 
          Juniores, ha vinto tutto quello che si poteva vincere. Da li a qualche anno formarono la colonna 
          vertebrale del nostro sodalizio conseguendo vittorie individuali e vittorie a squadre su tutti i campi di Italia 
          e ben figurando all’estero sia in rappresentanza del GsP Vergiate sia quado vestirono la divisa 
          della Nazionale Italiana.`
        },
        {
          img: require("@/assets/img/history/articolo.png"),
          text: `In seguito alla vittoria di Canegrate è 
          iniziata anche l’avventura europea del 
          Gsp Vergiate che nel 1996 ha 
          partecipato alla sua prima europa 
          Cup a Bystrice Pod Hostinem, nella 
          Repubblica Ceca e anche allora 
          partendo dalla gavetta: dodicesimi su 
          dodici esattamente come nel 1984 a 
          Bovisio ma con nel cuore tanta voglia 
          di migliorare e la consapevolezza di 
          vivere un’esperienza che avrebbe 
          lasciato un bagaglio tecnico 
          inestimabile.
          L’anno successivo Città del Lussemburgo con risultati ancora lontani dal vertice europeo. Poi nel 
          1998 l’Europa Cup viene assegnata all’Italia, a Paderno Dugnano ed, il Gsp Vergiate con 
          l’ennesima vittoria del titolo italiano a squadre si qualifica per l’ambitissima finale europea da 
          disputarsi appunto sull’impianto combinato (minigolf - miniaturgolf)  di Paderno Dugnano.  
          La squadra che partecipò alla finale fu la stessa che vinse i Campionati Italiani di Paderno: Rudi 
          Giroldini, Fabio Corio, Massimiliano Ciceri, Roberto Garbui, Domenico Garbui, Rodolfo Bozzetti e 
          Jean Louis Cazzato con gli accompagnatori Giancarlo Ravizzoli e Laura Barcelli per il campo di 
          minigolf e Tiziano Rizzi e Guido Mattaini per l’impianto di miniaturgolf.`
        },
        {
          text: `La gara è stata emozionante e fino all’ultimo l’esito è stato incerto con tre club che, colpo su colpo, 
          si sono contesi la vittoria fino all’ultimo: 
          Alla fine prevalsero i fortissimi tedeschi del Sußen, seguiti dai nostri portacolori davanti agli altrettanto forti 
          tedeschi dell’Hardenberg Pötter`
        },
        {
          imgFull: require("@/assets/img/history/europaCupPaderno.png")
        },
        {
          text: `Arriva il 1999, e l’anno nuovo ci vede ancora protagonisti con la vittoria del titolo Italiano a squadre 
          a Castellanza proprio davanti al consolidato club locale fino ad allora praticamente imbattibile sul 
          proprio campo. La squadra era composta da Rudi Giroldini, Rodolfo Bozzetti, Domenico Garbui, 
          Roberto Garbui, Giancarlo Ravizzoli, Fabio Corio e Massimiliano Ciceri con gli accompagnatori 
          Laura Barcellini e Guido Mattaini`
        },
        {
          text: `Anche questa vittoria è stata importante in quanto ha 
          dato sempre più fiducia ad un gruppo ormai ben 
          amalgamato proiettato verso successi sempre più 
          importanti. Il concetto di team era sempre più radicato 
          in noi ed è sicuramente ciò che ha fatto la differenza 
          rispetto a club potenzialmente più forti avendo 
          un'esperienza di gioco ben più solida della nostra.`,
          img: require("@/assets/img/history/articolo2.png")
        },
        {
          text: `Agli inizi degli anni 2000, il presidente, Tino Fiorani, propone di dare una svolta dal punto di vista 
          organizzativo alla nostra società sportiva. Nel corso di questi 
          16 anni è stato fatto molto ma ciò che mancava era un 
          grande evento: si decise allora di richiedere i Campionati 
          Italiani assoluti 2001. 
          Gli stessi ci furono assegnati ed il Gsp Vergiate, pur non 
          vincendo sul campo, consolida la propria leadership in qualità 
          di organizzatore di eventi con un contorno degno di una 
          competizione europea. Una conferenza stampa per 
          presentare la manifestazione, un rinfresco eccellente il giorno 
          prima della gara ed una organizzazione maniacale sono state 
          sicuramente le carte vincenti del club vergiatese grazie anche 
          all’amico svizzero Paolo Hägler il quale si è prestato alla 
          gestione dei risultati con il software della Federazione 
          Ticinese (forse è stato il primo campionato italiano completamente informatizzato con un 
          sistema stabile e certificato). La simpatica vignetta ideata da Paolo Belli è subito piaciuta al Cd ed 
          ha preso piede tra tutti i partecipanti dell’edizione 2001 dei Campionati Italiani Assoluti Individuali e 
          a Squadre. Se l’organizzazione è stata perfetta, il lato sportivo è stato deludente: per la prima volta ci si giocava 
          la qualificazione per due anni consecutivi alla Europa Cup e con il terzo posto conseguito sul 
          campo amico abbiamo dovuto rinunciare per il 2001 e il 2002 alla tanto ambita finale europea. A fine 2002 avvicendamento nel consiglio direttivo. 
          A seguito delle Dimissioni di Tino Fiorani viene rieletto Guido Mattaini come presidente del club e 
          con i nuovi consiglio direttivo continua sulla strada ormai tracciata da quasi 20 anni. 
          Si richiedono ed ottengono i Campionati Italiani Juniores e Seniores 2003 e, per il Gsp Vergiate, il 
          2003 appunto, sarà un altro anno magico: la squadra maschile composta da Ermes Franchini, 
          Rudi Giroldini, Giancarlo Ravizzoli, Marco Ambrosini, Massimiliano Ciceri Roberto Garbui, Fabio 
          Corio e l’accompagnatore Laura Barcellini si aggiudiga il Campionato Italiano a Squadre. 
          Nello stesso anno la squadra seniores composta da Rodolfo Bozzetti, Marco Ambrosini, 
          Giancarlo Ravizzoli, Guido Mattaini con gli accompagnatori Roberto Garbui (Coach) e Riccardo 
          Simonetta conquista, sul campo locale, il Titolo Italiano a Squadre Seniores.`
        },
        {
          imgFull: require("@/assets/img/history/anno2003.png")
        },
        {
          text: `Dopo tanti anni anche la rinata squadra Juniores ritrova la vittoria. Infatti i nostri ragazzi, Marcello 
          Mariotti, Jessica Agostino, Samuele Agostino e Lorenzo Levis guidati da Rudi Giroldini 
          conquistano il titolo nazionale a squadre di categoria.`
        },
        {
          text: `Proprio in quest’ anno magico una storia tutta vergiatese. 
          Una sera della tarda primavera del 2003, il Consiglio Direttivo del Gsp Vergiate era riunito per 
          l’organizzazione dei Campionati Italiani Seniores e Juniores che si sarebbero disputati a luglio 
          proprio sull’impianto vergiatese.  
          Dopo aver preparato una scaletta delle attività da svolgere per organizzare al meglio una 
          manifestazione così importante incominciammo a parlare delle squadre da presentare. 
          Per la squadra Seniores avevamo una rosa di giocatori molto ampia tra i quali il Coach Roby Garbui 
          avrebbe potuto scegliere mentre per i ragazzi avevamo a disposizione solo tre giocatori: Marcello 
          Mariotti (già Campione Italiano under 15 nel 2002), Jessica Agostino e Samuele Agostino, fratello 
          di Jessica. Dopo aver affidato la gestione della squadra juniores a Rudi Giroldini, giocatore di 
          grande talento ed indubbio trascinatore, ci salutiamo e concludiamo la serata con la convinzione di 
          aver gettato delle buone basi.`
        },
        {
          text: `Dopo qualche giorno arriva la notizia che Marcello si è fatto male piuttosto seriamente e la sua 
          partecipazione agli imminenti Campionati Italiani era in forse. 
          Oltre al dispiacere per l’incidente capitato a Marcello eravamo anche abbattuti in quanto non 
          avremmo presentato la squadra proprio nell’edizione casalinga dei Campionati Italiani Seniores e 
          Juniores. Passano i giorni e le condizioni di Marcello migliorano ma non  in modo rassicurante per 
          la sua partecipazione alla gara. A questo punto, se non ricordo male, Rudi dice a Mauro :<< 
          Perché non chiedi a tuo figlio Lorenzo se vuol provare a giocare a minigolf? Almeno presentiamo 
          la squadra!>> 
          Dopo qualche giorno di presenta al minigolf un ragazzino, esile, forse un po’ spaventato ma con un 
          ampio sorriso che subito ci ha conquistato: dopo pochi minuti era già “Cipollino”. Nell’arco di due 
          mesi, Rudi riesce a portare la nostra mascotte Cipollino ad un livello di gioco accettabile per una 
          manifestazione così importante.  
          Marcello intanto recupera ed ottiene il permesso dai sanitari di giocare ma con l’ausilio di un tutore 
          e l’obbligo di usare le stampelle per deambulare. Poiché la gara a squadre era a quattro giocatori 
          con lo scarto del peggior punteggio decidiamo comunque di far giocare Cipollino anche perché 
          aveva passato più tempo lui al minigolf di tutti gli altri messi insieme e quindi era giusto premiarlo. 
          La gara si svolge regolarmente: Marcello, sofferente, non vince l’individuale ma trascina la squadra 
          al titolo nazionale, ben seguito a fasi alterne, da Jessica, Samuele ed un determinatissimo quanto 
          spaventato Cipollino. 
          Due, a mio avviso sono stati gli episodi determinanti per l’inizio della carriera minigolfistica di 
          Cipollino. 
          Il primo è sicuramente aver preso fiducia nei propri mezzi raggiungendo, al primo tentativo, il titolo 
          di campione italiano a squadre. 
          Il secondo è l’aver incontrato sulla propria strada un grande signore quale è Pietro Gritti. 
          In pochi forse ricordano un episodio accaduto durante la gara: Pietro è alla 18 in uno dei giri di 
          qualificazione; sta tirando un punteggio verde quindi molto basso. La palla si incanala verso la 
          casetta di arrivo quando Cipollino ferma la palla durante la sua corsa esclamando << Bravo 
          non è consentito!! In un attimo realizza che ha commesso un errore e guardando impaurito Pietro 
          Pietro!!>>. È un gesto che in allenamento ha visto fare decine di volte ma ora siamo in gara e ciò 
          negli occhi scoppia a piangere!! A questo punto Pietro gli si avvicina, lo abbraccia e lo rassicura. 
          Ritorna a tirare la 18 (facendo 3 e bruciandosi il punteggio verde) e poi ritorna da Cipollino sempre per 
          rassicurarlo senza scenate e/o scatti d’ira. 
          Credo che questo passaggio sia stato fondamentale affinché Cipollino continuasse a giocare e 
          crescere (oltre che in altezza) come giocatore di minigolf.`
        },
        {
          text: `Il 2003 segna anche l’anno record di iscritti al nostro club arrivato a 56 e consacrandoci il club più 
          numeroso d’Italia`
        },
        {
          imgFull: require("@/assets/img/history/club2003.png")
        },
        {
          text: `Nel 2004 la squadra seniores bissa la vittoria del 2003  
          aggiudicandosi il titolo italiano a squadre seniore a 
          Lozza con la compagine composta da Rodolfo Bozzetti, 
          Maurizio Carabelli, Giancarlo Ravizzoli, Marco Ambrosini 
          allenati da Roberto Garbui`
        },
        {
          text: `Nel 2005 anche la squadra femminile di Vergiate conquista il titolo italiano a squadre e conquista il 
          diritto a partecipare alla edizione 2006 della Coppa Europa ad Alges (Portogallo). 
          La squadra composta da Simona Valenti,Paola Tecchio, Jessica Agostino, Alessandra Cudia con 
          gli accompagnatori Michele Da Prada e Roberto Raspanti ben figurano non raggiungendo però la 
          super finale a 6. 
          Per quanto riguarda la Europa Cup, la squadra maschile vergiatese ha partecipato nel 2006 a 
          Romanshorn in Svizzera conquistando il sesto posto, nel 2007 a Hilzingen(Germania) conquistando 
          il sesto posto, nel 2009 a Vaduz centrando sempre la finale a 6 come pure nel 2010 a Vienna. 
          Prima di parlare dell’edizone 201 dell’Europa Cup, facciamo un passo indietro. Nel 2007 il presidente Guido Mattaini 
          popone al consiglio l’organizzazione dei campionati italiani assoluti e a squadre 2008 da utilizzare come prova generale per capire se fossimo pronti per 
          organizzare un grande evento europeo. 
          L’organizzazione ei campionai Italiani 2008 è stato un grande successo sia dal punto di vista 
          organizzativo sia per quanto riguarda i risultati: 
          Vittoria a squadre con Rudi Giroldini, Gianfranco Tedeschi, Riccardo Simonetta, Roberto Garbui, 
          Ivan Baruscotti, Domenico Garbui, Massimiliano Ciceri con gli accompagnatori Juri Ghiraldi e 
          Michele da Prada e vittoria individuale di Rudi Giroldini. 
          Da questo successo organizzativo si decide di richiedere l’Europa Cup 2011 ed una volta ottenuta 
          l’organizzazione siamo partiti a spron battuto con la consapevolezza di poter organizzare un 
          grande evento. 
          La preparazione del campo, la sfilata per le vie del paese, la cerimonia di apertura presso 
          l’Auditorium Agusta Westland, la gara e la cerimonia di chiusura preso la discoteca Picasso hanno 
          lasciato n segno indelebile nel cuore dei partecipanti, giocatori, accompagnatori ed autorità federali`
        },
        {
          text: `Oggi il Gsp Vergiate è tra i club più numerosi in Italia, continua a raggiungere traguardi sempre più 
          ambiziosi e con grande continuità rappresenta l’Italia nella Coppa Europa, manifestazione che nel 
          2012 si svolgerà Vizela, Portogallo e ci vedrà ancora protagonisti.. 
          Per noi però il minigolf non è solo pratica sportiva ma soprattutto voglia e piacere di stare insieme 
          e creare un gruppo coeso ed impegnato seriamente allo stesso tempo  con tanta voglia di stare in 
          allegra compagnia. 
          Per meglio spiegare quanto affermato due esempi concreti. 
          In trasferta a Menaggio, sul Lago di Como, per una gara nazionale, come sempre, il gruppo è ben 
          nutrito ed alla fine della giornata, visto il grande traffico sulla strada del ritorno, decidiamo di 
          fermarci in pizzeria per poi rincasare in tarda serata. Alla nostra tavolata, all’aperto, si uniscono 
          giocatori/trici di altri club con le rispettive mogli o mariti che avevano avuto la nostra stessa idea. `
        },
        {
          text: `Tra le nostre file abbiamo parecchi giocatori di livello nazionale ed internazionale ma vorrei 
          spendere una parola particolare pr Lorenzo Levis. 
          Ora Lorenzo è “Cipo” per tutti, compresi i temutissimi avversari stranieri con i quali ha imparato a 
          confrontarsi al loro pari, senza timori riverenziali ma con la consapevolezza di poter, colpo dopo 
          colpo arrivare davanti a tutti. 
          Dopo il quarto posto ai Campionati Europei Juniores dell'anno 2009 a Waldshut, nel 2010 ha 
          conquistato il secondo posto ai Campionati Italiani assoluti, ed ha confermato il quarto posto ai 
          Campionati Mondiali Juniores di Sochi, in Russia, avendo dominato i percorsi di qualifica 
          classificandosi al primo posto. 
          Solo la lotteria degli scontri diretti l’ha fermato alle semifinali impedendogli di coronare un successo 
          meritato, costruito giorno dopo giorno, pista dopo pista.  
          Nel 2011 ha ancora raggiunto risultati di rilievo classificandosi secondo ai campionati europei juniores
          e a fine anno al terzo posto del ranking mondiale 
          Sono passati solo 7 anni da quella sera in cui, disperati, cercavamo un ragazzino per completare 
          la squadra: oggi posso dire che abbiamo trovato un campione. `
        },
        {
          text: `Questi sono solo alcuni episodi di oltre 25 anni di minigolf giocato ad alti livelli, su tutti i campi 
          italiani e su tanti impianti all’estero dove i nostri portacolori hanno sempre ben figurato sia per i 
          risultati sportivi sia per la loro correttezza. 
          Dopo la Coppa Europa 2011 il presidente Guido Mattaini ha deciso di rassegnare le dimissioni 
          lasciando il passo a chi, con nuove motivazioni e voglia di fare, possa portare sempre più in alto il 
          Gsp Vergiate. `
        }
      ]
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
