<template>
  <v-container fluid>
    <v-row class="display-3 mb-5 mt-10 text-center" justify="center"
      >Campionato Sociale 2020</v-row
    >
    <v-tabs color="secondary" class="mt-5" v-model="tab" center-active>
      <v-tab v-for="item in gare" :key="item.nome" touchless>
        <v-icon left>{{ item.icon }}</v-icon>
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item v-for="item in gare" :key="item.nome">
        <v-card flat class="mx-0 px-0">
          <v-card-text v-if="item.title != 'Categorie'">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr v-if="item.title != 'Ideal Score'">
                    <th>Giocatore</th>
                    <th class="text-center">1^ Sociale</th>
                    <th class="text-center">2^ Sociale</th>
                    <th class="text-center">3^ Sociale</th>
                    <th class="text-center">4^ Sociale</th>
                    <th class="text-center">5^ Sociale</th>
                    <th class="text-center">6^ Sociale</th>
                    <th class="text-center">7^ Sociale</th>
                    <th class="text-center">Punti Totali</th>
                  </tr>
                  <tr v-else>
                    <th>Giocatore</th>
                    <th class="text-center">1</th>
                    <th class="text-center">2</th>
                    <th class="text-center">3</th>
                    <th class="text-center">4</th>
                    <th class="text-center">5</th>
                    <th class="text-center">6</th>
                    <th class="text-center">7</th>
                    <th class="text-center">8</th>
                    <th class="text-center">9</th>
                    <th class="text-center">10</th>
                    <th class="text-center">11</th>
                    <th class="text-center">12</th>
                    <th class="text-center">13</th>
                    <th class="text-center">14</th>
                    <th class="text-center">15</th>
                    <th class="text-center">16</th>
                    <th class="text-center">17</th>
                    <th class="text-center">18</th>
                    <th class="text-center"># Mancanti</th>
                  </tr>
                </thead>
                <tbody v-if="item.title == 'Classifica Generale'">
                  <tr v-for="giocatore in generalClass" :key="giocatore.nome">
                    <td>{{ giocatore.nome }}</td>
                    <td class="text-center">{{ giocatore.punti[0] }}</td>
                    <td class="text-center">{{ giocatore.punti[1] }}</td>
                    <td class="text-center">{{ giocatore.punti[2] }}</td>
                    <td class="text-center">{{ giocatore.punti[3] }}</td>
                    <td class="text-center">{{ giocatore.punti[4] }}</td>
                    <td class="text-center">{{ giocatore.punti[5] }}</td>
                    <td class="text-center">{{ giocatore.punti[6] }}</td>
                    <td class="font-weight-black text-center">
                      {{ giocatore.totalPoints }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="item.title == 'Ideal Score'">
                  <tr v-for="giocatore in getIdealScore" :key="giocatore.nome">
                    <td>{{ giocatore.nome }}</td>
                    <td
                      v-for="(score, index) in giocatore.idealScore"
                      :key="index"
                      :class="score == 'O' ? 'red--text' : ''"
                    >
                      {{ score }}
                    </td>
                    <td class="font-weight-black text-center">
                      {{ giocatore.missingLane }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-text v-else>
            <v-row class="mt-2">
              <v-col cols="12" class="title">{{ secondCategory.title }}</v-col>
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Giocatore</th>
                        <th class="text-center">1^ Sociale</th>
                        <th class="text-center">2^ Sociale</th>
                        <th class="text-center">3^ Sociale</th>
                        <th class="text-center">4^ Sociale</th>
                        <th class="text-center">5^ Sociale</th>
                        <th class="text-center">6^ Sociale</th>
                        <th class="text-center">7^ Sociale</th>
                        <th class="text-center">Punti Totali</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="giocatore in secondCategory.players"
                        :key="giocatore.nome"
                      >
                        <td>{{ giocatore.nome }}</td>
                        <td class="text-center">{{ giocatore.punti[0] }}</td>
                        <td class="text-center">{{ giocatore.punti[1] }}</td>
                        <td class="text-center">{{ giocatore.punti[2] }}</td>
                        <td class="text-center">{{ giocatore.punti[3] }}</td>
                        <td class="text-center">{{ giocatore.punti[4] }}</td>
                        <td class="text-center">{{ giocatore.punti[5] }}</td>
                        <td class="text-center">{{ giocatore.punti[6] }}</td>
                        <td class="text-center font-weight-black">
                          {{ giocatore.totalPoints }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col cols="12" class="title">{{ womanCategory.title }}</v-col>
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Giocatore</th>
                        <th class="text-center">1^ Sociale</th>
                        <th class="text-center">2^ Sociale</th>
                        <th class="text-center">3^ Sociale</th>
                        <th class="text-center">4^ Sociale</th>
                        <th class="text-center">5^ Sociale</th>
                        <th class="text-center">6^ Sociale</th>
                        <th class="text-center">7^ Sociale</th>
                        <th class="text-center">Punti Totali</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="giocatore in womanCategory.players"
                        :key="giocatore.nome"
                      >
                        <td>{{ giocatore.nome }}</td>
                        <td class="text-center">{{ giocatore.punti[0] }}</td>
                        <td class="text-center">{{ giocatore.punti[1] }}</td>
                        <td class="text-center">{{ giocatore.punti[2] }}</td>
                        <td class="text-center">{{ giocatore.punti[3] }}</td>
                        <td class="text-center">{{ giocatore.punti[4] }}</td>
                        <td class="text-center">{{ giocatore.punti[5] }}</td>
                        <td class="text-center">{{ giocatore.punti[6] }}</td>
                        <td class="text-center font-weight-black">
                          {{ giocatore.totalPoints }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col cols="12" class="title">{{ juniorCategory.title }}</v-col>
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Giocatore</th>
                        <th class="text-center">1^ Sociale</th>
                        <th class="text-center">2^ Sociale</th>
                        <th class="text-center">3^ Sociale</th>
                        <th class="text-center">4^ Sociale</th>
                        <th class="text-center">5^ Sociale</th>
                        <th class="text-center">6^ Sociale</th>
                        <th class="text-center">7^ Sociale</th>
                        <th class="text-center">Punti Totali</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="giocatore in juniorCategory.players"
                        :key="giocatore.nome"
                      >
                        <td>{{ giocatore.nome }}</td>
                        <td class="text-center">{{ giocatore.punti[0] }}</td>
                        <td class="text-center">{{ giocatore.punti[1] }}</td>
                        <td class="text-center">{{ giocatore.punti[2] }}</td>
                        <td class="text-center">{{ giocatore.punti[3] }}</td>
                        <td class="text-center">{{ giocatore.punti[4] }}</td>
                        <td class="text-center">{{ giocatore.punti[5] }}</td>
                        <td class="text-center">{{ giocatore.punti[6] }}</td>
                        <td class="text-center font-weight-black">
                          {{ giocatore.totalPoints }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  name: "sociali-item",
  components: {},
  data() {
    return {
      tab: 0,
      gare: [
        {
          title: "Classifica Generale",
          icon: "mdi-trophy"
        },
        {
          title: "Ideal Score",
          icon: "mdi-star"
        },
        {
          title: "Categorie",
          icon: "mdi-table-of-contents"
        }
      ],
      generalClass: [
        {
          nome: "Graffeo Michele",
          punti: [28.5, 30, 14, 12, 22, 27, 0],
          idealScore: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        },
        {
          nome: "Giroldini Rudi",
          punti: [24, 25.5, 20, 18, 0, 0, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1]
        },
        {
          nome: "Garbui Domenico",
          punti: [28.5, 25.5, 6, 20, 22, 15, 0],
          idealScore: [1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1]
        },
        {
          nome: "Gavazzi Luca",
          punti: [20, 20, 7, 0, 27, 30, 0],
          idealScore: [1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1]
        },
        {
          nome: "Ciceri Massimiliano",
          punti: [11, 12, 17, 12, 0, 10, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 1, 1]
        },
        {
          nome: "Zini Maurizio",
          punti: [11, 16, 12, 28.5, 16, 15, 0],
          idealScore: [1, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        },
        {
          nome: "Ravizzoli Marco",
          punti: [7, 0, 24, 6, 12, 12, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1]
        },
        {
          nome: "Lorenzo Levis",
          punti: [0, 0, 30, 28.5, 30, 18, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        },
        {
          nome: "Catanzaro Anna",
          punti: [11, 16, 2.25, 4, 8, 7.5, 0],
          idealScore: [1, 1, 0, 0, 1, 1, 0, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1]
        },
        {
          nome: "Cudia Alessandra",
          punti: [18, 4, 5, 1, 1, 1, 0],
          idealScore: [1, 1, 0, 0, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1]
        },
        {
          nome: "Massazza Cristian",
          punti: [2, 16, 9, 1, 0, 2.5, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        },
        {
          nome: "Baruscotti Yvan",
          punti: [0, 0, 27, 16, 0, 0, 0],
          idealScore: [1, 1, 0, 0, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1, 1]
        },
        {
          nome: "Mattaini Guido",
          punti: [0, 8.3, 17, 7.5, 5, 24, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        },
        {
          nome: "Giroldini Ryan",
          punti: [16, 4, 1, 12, 0, 0, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 0, 0, 1, 1, 1, 0, 1, 1, 0, 1, 1, 1]
        },
        {
          nome: "Cristofalo Concetta",
          punti: [11, 1, 1, 1, 0, 1, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        },
        {
          nome: "Perazzolo Niccolò",
          punti: [1, 8.3, 2.25, 0, 0, 0, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        },
        {
          nome: "Fiorani Innocente",
          punti: [1.5, 1, 9, 1, 3, 1, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1]
        },
        {
          nome: "Girola Renato",
          punti: [5, 4, 2.25, 7.5, 7, 7.5, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1]
        },
        {
          nome: "Salvi Alfredo",
          punti: [4, 6, 1, 24, 18, 6, 0],
          idealScore: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1, 0, 1, 0, 1]
        },
        {
          nome: "Olivieri Marco",
          punti: [0, 8.3, 1, 2, 0, 0, 0],
          idealScore: [1, 1, 1, 0, 1, 0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 1, 1]
        },
        {
          nome: "Santabarbara Luca",
          punti: [6, 1, 2.25, 5, 10, 1, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 0, 0, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1]
        },
        {
          nome: "De Pascale Elio",
          punti: [1.5, 1, 1, 1, 1, 1, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 0, 0, 0, 1]
        },
        {
          nome: "Mazzuchelli Mauro",
          punti: [1, 1, 1, 1, 4, 1, 0],
          idealScore: [1, 1, 1, 0, 1, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1]
        },
        {
          nome: "Levis Mauro",
          punti: [1, 0, 1, 1, 0, 2.5, 0],
          idealScore: [1, 1, 1, 1, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1]
        },
        {
          nome: "Sanna Ivano",
          punti: [0, 1, 1, 2, 6, 4.5, 0],
          idealScore: [1, 1, 1, 0, 1, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 1, 1]
        },
        {
          nome: "Velati Roberto",
          punti: [0, 1, 1, 0, 0, 0, 0],
          idealScore: [1, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 0, 1, 1]
        },
        {
          nome: "Ghiraldi Juri",
          punti: [0, 1, 0, 0, 0, 0, 0],
          idealScore: [1, 1, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1]
        },
        {
          nome: "Simonetta Riccardo",
          punti: [0, 1, 0, 0, 0, 0, 0],
          idealScore: [1, 1, 1, 0, 0, 0, 1, 0, 1, 1, 1, 0, 1, 1, 0, 0, 0, 1]
        },
        {
          nome: "Mazzari Stefano",
          punti: [0, 1, 0, 1, 2, 1, 0],
          idealScore: [1, 1, 0, 0, 1, 1, 0, 0, 0, 1, 1, 0, 1, 0, 1, 0, 0, 1]
        },
        {
          nome: "Vittimani Giancarlo",
          punti: [0, 1, 0, 0, 1, 0, 0],
          idealScore: [0, 0, 1, 0, 1, 0, 0, 0, 0, 1, 1, 0, 1, 0, 0, 0, 1, 1]
        },
        {
          nome: "Valenti Simona",
          punti: [0, 1, 0, 0, 0, 0, 0],
          idealScore: [0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1]
        },
        {
          nome: "Rizzi Tiziano",
          punti: [0, 1, 0, 0, 0, 0, 0],
          idealScore: [0, 1, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1]
        },
        {
          nome: "Belli Riccardo",
          punti: [0, 0, 1, 1, 0, 0, 0],
          idealScore: [1, 1, 0, 0, 1, 0, 0, 0, 0, 1, 1, 0, 1, 1, 0, 0, 1, 1]
        },
        {
          nome: "Garbui Roberto",
          punti: [0, 0, 0, 0, 0, 20, 0],
          idealScore: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          nome: "Marcoaldi Valerio",
          punti: [0, 0, 0, 0, 0, 4.5, 0],
          idealScore: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          nome: "Lasia Gianfranco",
          punti: [0, 0, 0, 0, 0, 1, 0],
          idealScore: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          nome: "Salina Michele",
          punti: [0, 0, 0, 0, 0, 1, 0],
          idealScore: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
      secondCategory: {
        title: "Seconda Categoria",
        players: [
          {
            nome: "Rizzi Tiziano",
            punti: [0, 10, 0, 0, 0, 0, 0]
          },
          {
            nome: "Massazza Cristian",
            punti: [24, 30, 26, 20, 0, 19, 0]
          },
          {
            nome: "Salvi Alfredo",
            punti: [27, 24, 14, 30, 30, 27, 0]
          },
          {
            nome: "Perazzolo Niccolò",
            punti: [14, 27, 24, 0, 0, 0, 0]
          },
          {
            nome: "Ravizzoli Marco",
            punti: [30, 0, 30, 27, 27, 30, 0]
          },
          {
            nome: "Fiorani Innocente",
            punti: [20, 12, 26, 18, 18, 16, 0]
          },
          {
            nome: "Vittimani Giancarlo",
            punti: [0, 14, 0, 0, 14, 0, 0]
          },
          {
            nome: "Mazzuchelli Mauro",
            punti: [16, 18, 12, 12, 20, 14, 0]
          },
          {
            nome: "Sanna Ivano",
            punti: [0, 20, 17, 24, 24, 24, 0]
          },
          {
            nome: "Levis Mauro",
            punti: [18, 0, 17, 16, 0, 19, 0]
          },
          {
            nome: "Velati Roberto",
            punti: [0, 8, 10, 0, 0, 0, 0]
          },
          {
            nome: "Mazzari Stefano",
            punti: [0, 16, 0, 14, 16, 11, 0]
          },
          {
            nome: "Lasia Gianfranco",
            punti: [0, 0, 0, 0, 0, 1, 0]
          }
        ]
      },
      womanCategory: {
        title: "Donne",
        players: [
          { nome: "Cudia Alessandra", punti: [30, 27, 30, 27, 27, 24, 0] },
          {
            nome: "Catanzaro Anna",
            punti: [25.5, 30, 27, 30, 30, 30, 0]
          },
          { nome: "Cristofalo Concetta", punti: [25.5, 24, 24, 24, 0, 27, 0] },
          { nome: "Valenti Simona", punti: [0, 20, 0, 0, 0, 0, 0] }
        ]
      },
      juniorCategory: {
        title: "Juniores",
        players: [
          {
            nome: "Giroldini Ryan",
            punti: [30, 30, 30, 30, 0, 0, 0]
          },
          { nome: "Belli Riccardo", punti: [0, 0, 27, 27, 0, 0, 0] },
          { nome: "Marcoaldi Valerio", punti: [0, 0, 0, 0, 0, 30, 0] }
        ]
      }
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    for (let i = 0; i < this.generalClass.length; i++) {
      let pointSorted = this.generalClass[i].punti.slice();
      pointSorted = pointSorted.sort(function(a, b) {
        return a - b;
      });
      pointSorted.splice(0, 2);
      this.generalClass[i].totalPoints = pointSorted.reduce((a, b) => a + b, 0);
    }
    this.generalClass.sort((a, b) => (a.totalPoints < b.totalPoints ? 1 : -1));
    for (let i = 0; i < this.secondCategory.players.length; i++) {
      let pointSorted = this.secondCategory.players[i].punti.slice();
      pointSorted = pointSorted.sort(function(a, b) {
        return a - b;
      });
      pointSorted.splice(0, 2);
      this.secondCategory.players[i].totalPoints = pointSorted.reduce(
        (a, b) => a + b,
        0
      );
    }
    this.secondCategory.players.sort((a, b) =>
      a.totalPoints < b.totalPoints ? 1 : -1
    );
    for (let i = 0; i < this.womanCategory.players.length; i++) {
      let pointSorted = this.womanCategory.players[i].punti.slice();
      pointSorted = pointSorted.sort(function(a, b) {
        return a - b;
      });
      pointSorted.splice(0, 2);
      this.womanCategory.players[i].totalPoints = pointSorted.reduce(
        (a, b) => a + b,
        0
      );
    }
    this.womanCategory.players.sort((a, b) =>
      a.totalPoints < b.totalPoints ? 1 : -1
    );
    for (let i = 0; i < this.juniorCategory.players.length; i++) {
      let pointSorted = this.juniorCategory.players[i].punti.slice();
      pointSorted = pointSorted.sort(function(a, b) {
        return a - b;
      });
      pointSorted.splice(0, 2);
      this.juniorCategory.players[i].totalPoints = pointSorted.reduce(
        (a, b) => a + b,
        0
      );
    }
    this.juniorCategory.players.sort((a, b) =>
      a.totalPoints < b.totalPoints ? 1 : -1
    );
  },
  computed: {
    getIdealScore() {
      let idealScore = [];
      for (let giocatore of this.generalClass) {
        let obj = {
          nome: giocatore.nome,
          idealScore: []
        };
        let counter = 0;
        for (let index in giocatore.idealScore) {
          if (giocatore.idealScore[index] == 0) {
            obj.idealScore[index] = "O";
            counter++;
          } else {
            obj.idealScore[index] = "X";
          }
        }
        obj.missingLane = counter;
        idealScore.push(obj);
      }
      idealScore.sort((a, b) => (a.missingLane > b.missingLane ? 1 : -1));
      return idealScore;
    }
  }
};
</script>
