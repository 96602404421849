<template>
  <div>
    <v-app-bar app color="secondary" dark>
      <v-row align="center" justify="center">
        <v-img :src="require('../assets/img/logoGspVergiate.png')" max-height="50" max-width="50" />
        <router-link to="/" class="toolbar-title">
          <v-toolbar-title class="ml-3 white--text">GSP Vergiate</v-toolbar-title>
        </router-link>
        <v-spacer></v-spacer>
        <v-toolbar-items class="d-none d-md-flex">
          <v-btn class="py-3" text href="http://golfsupistavergiate.altervista.org/yec2023/index.html" target="_blank">YEC 2023</v-btn>
        </v-toolbar-items>
        <v-toolbar-items class="d-none d-md-flex" v-for="(link, index) in links" :key="index">
          <v-btn class="py-3" text :to="link.to">{{link.title}}</v-btn>
        </v-toolbar-items>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn class="white--text d-flex d-md-none" text dark v-on="on">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(link, index) in links" :key="index" :to="link.to">
              <v-list-item-title>{{ link.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  name: "Nav-bar",
  data() {
    return {
      links: [
        { title: "Galleria", to: "/galleria" },
        { title: "Storia", to: "/storia" },
        { title: "Palmares", to: "/palmares" },
        { title: "Campionato Sociale", to: "/sociali" },
        { title: "Trofeo S. Martino", to: "/sanmartino" },
        { title: "Organigramma", to: "/organigramma" },
        { title: "Documenti", to: "/documenti" }
      ]
    };
  }
};
</script>

<style>
.toolbar-title {
  text-decoration: none;
}
</style>