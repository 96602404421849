<template>
  <v-container fluid>
    <v-row>
      <v-col v-for="(image, index) in images" :key="index" cols="12" md="6" lg="4" class="pa-5">
        <v-img :src="image.src"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "gallery-item",
  data() {
    return {
      images: [
        {
          src: require("@/assets/img/gallery/20150719_193911.jpg")
        },
        {
          src: require("@/assets/img/gallery/20150719_194419.jpg")
        },
        {
          src: require("@/assets/img/gallery/FB_IMG_1500377757640.jpg")
        },
        {
          src: require("@/assets/img/gallery/verticale4.jpg")
        },
        {
          src: require("@/assets/img/gallery/verticale5.jpg")
        },
        {
          src: require("@/assets/img/gallery/verticale6.jpg")
        },
        {
          src: require("@/assets/img/gallery/foto_squadra_lagundo.jpg")
        },
        {
          src: require("@/assets/img/gallery/IMG_2833.jpg")
        },
        {
          src: require("@/assets/img/gallery/IMG-20160611-WA0002.jpg")
        },
        {
          src: require("@/assets/img/gallery/IMG-20181114-WA0010(1).jpg")
        },
        {
          src: require("@/assets/img/gallery/TeamVergiateCIS2012.jpg")
        },
        {
          src: require("@/assets/img/gallery/2005727202939_204207.jpg")
        },
        {
          src: require("@/assets/img/gallery/verticale1.jpg")
        },
        {
          src: require("@/assets/img/gallery/verticale2.jpg")
        },
        {
          src: require("@/assets/img/gallery/verticale3.jpg")
        },
        {
          src: require("@/assets/img/gallery/IMG_2833.jpg")
        },
        {
          src: require("@/assets/img/gallery/aereoNew.jpg")
        },
        {
          src: require("@/assets/img/gallery/aereoOld.jpg")
        },
        {
          src: require("@/assets/img/gallery/ec2011apertura.jpg")
        },
        {
          src: require("@/assets/img/gallery/maxRudy.jpg")
        },
        {
          src: require("@/assets/img/gallery/training.jpg")
        },
        {
          src: require("@/assets/img/gallery/sfilata.jpg")
        },
        {
          src: require("@/assets/img/gallery/ec2011jury.jpg")
        },
        {
          src: require("@/assets/img/gallery/ec2011max.jpg")
        },
        {
          src: require("@/assets/img/gallery/ec2011mike.jpg")
        },
        {
          src: require("@/assets/img/gallery/ec2011roby.jpg")
        },
        {
          src: require("@/assets/img/gallery/ec2011team.jpg")
        },
        {
          src: require("@/assets/img/gallery/ec2011podio.jpg")
        },
        {
          src: require("@/assets/img/gallery/ec2011premiazioneCampo.jpg")
        },
        {
          src: require("@/assets/img/gallery/ec2011premizioneClosing.jpg")
        }
      ]
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
